<template>
  <app-container
    icon="edit_document"
    colWidth="col-12 col-lg-10"
    v-if="!isTextLoading"
  >
    <template #back>
      <app-back
        :routeName="texts.back_link.route"
        :text="texts.back_link.text"
        colWidth="col-12 col-lg-10"
      />
    </template>
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="row tool-row" v-if="showForm">
        <div class="col-12 col-lg-6 mt-5">
          <el-form :model="model" label-position="top">
            <template v-for="(item, index) in form" :key="index">
              <el-form-item :label="getLabel(item)">
                <component
                  v-if="item.name !== 'client_location'"
                  :is="item.component"
                  v-model="model[item.name]"
                  :placeholder="item.placeholder"
                  :type="item.type"
                  :multiple="item?.multiple"
                  :clearable="item.type === 'select' ? true : null"
                  :disabled="
                    index > currentIndex ||
                    item.name === 'density' ||
                    (item.name === 'is_galvanized' &&
                      !material.can_be_galvanized)
                  "
                  size="large"
                  @change="handleChange(index, item, 'change')"
                >
                  <template v-if="item.component === 'el-select'">
                    <el-option
                      v-for="(option, index) in item.options"
                      :key="index"
                      :value="option.label"
                      >{{ option.label }}</el-option
                    >
                  </template>
                </component>
                <el-input
                  v-else
                  v-model="model[item.name]"
                  :placeholder="item.placeholder"
                  :type="item.type"
                  :max="item.maxLength"
                  @blur="handleChange(index, item, 'blur')"
                  @input="handleChange(index, item, 'input')"
                  @change="handleChange(index, item, 'change')"
                  :disabled="index > currentIndex"
                  size="large"
                />
              </el-form-item>
              <div class="form-error" v-if="errors[item.name].length">
                <p
                  v-for="(error, errIndex) in errors[item.name]"
                  :key="errIndex"
                >
                  {{ error }}
                </p>
              </div>
            </template>
          </el-form>
          <div class="mt-5 mb-2 text-start">
            <button class="btn" @click="handleSubmit">
              {{ texts.button }}
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="tool-img">
            <img :src="img" />
          </div>
        </div>
      </div>
    </template>
  </app-container>
</template>

<script>
/**
 * TODO:
 * - Reset next fields on value update
 * - Add validation
 * - Add loader
 * - Fix limit on zip code field
 */

import useTexts from '@/mixins/useTexts';
import Validator from '@/utils/services/Validator';
import Utilities from '@/utils/services/Utilities';
import Database from '@/utils/services/Database';

export default {
  name: 'Step2Page',
  mixins: [useTexts],
  data() {
    return {
      page: 'configurator_2',
      family: {},
      img: '',
      product: {},
      material: {},
      model: {},
      form: [],
      rules: {},
      errors: {},
      showForm: false,
      currentIndex: 0,
      keys: [],
      is_zip_checked: false,
    };
  },
  async created() {
    this.isLoading = true;
    this.family = JSON.parse(localStorage.getItem('product_family'));
    this.img = this.family.img_url;
    await this.setupForm(this.family.key);
    this.isLoading = false;
  },
  async unmounted() {
    await this.resetForm(this.family.key);
  },
  methods: {
    async resetForm(family_key) {
      const { form, model, rules } =
        await require(`@/config/forms/tool/${family_key}`);

      this.form = JSON.parse(JSON.stringify(form));
      this.model = JSON.parse(JSON.stringify(model));
      this.rules = JSON.parse(JSON.stringify(rules));

      this.keys = Object.keys(this.model);
      for (let i in this.keys) this.errors[this.keys[i]] = [];
    },
    async setupForm(family_key) {
      this.showForm = false;

      this.currentIndex = 0;
      await this.resetForm(family_key);

      const products = await Database.getAll('products', {
        is_setup: true,
        family_id: this.family.id,
      });

      this.product = products[0];
      this.showForm = true;
    },
    async handleChange(index, item, eventType) {
      this.currentIndex = index;

      // this.resetNextFields(index, item);

      const key = item.name;

      if (key === 'material_name') {
        let options = item.options;
        this.material = options.find(
          (option) => option.label === this.model.material_name
        );
      }

      if (key === 'product_model') {
        let options = item.options;
        const selectedOption = options.find(
          (option) => option.label === this.model.product_model
        );
        this.img = selectedOption.img_url;
      }

      if (key === 'client_location') return this.checkZipcode(eventType, index);

      return this.getOptions();
    },
    async getOptions() {
      this.currentIndex++;

      const activeKey = this.keys[this.currentIndex];

      console.log(activeKey);

      const isRequest = this.form[this.currentIndex].is_request;
      if (!isRequest) return;

      const isEmpty = Object.keys(this.product).length === 0;
      if (isEmpty) return;

      const product = await Database.getOne('products', this.product.id, {
        product: {
          id: this.product.id,
          family_id: this.product.family_id,
          has_models: this.product.has_models,
        },
        active_key: activeKey,
        model: {
          ...this.model,
          material_id: this.material.id ?? null,
          material_type_id: this.material.material_type_id ?? null,
        },
      });

      this.product = product;

      if (activeKey === 'density') {
        this.model.density = this.product.density;

        this.form[this.currentIndex] = {
          ...this.form[this.currentIndex],
          placeholder: this.product.density.toString(),
        };

        this.currentIndex++;
        console.log(this.form[this.currentIndex], this.material);

        if (
          this.form[this.currentIndex].name === 'is_galvanized' &&
          !this.material.can_be_galvanized
        ) {
          this.form[this.currentIndex] = {
            ...this.form[this.currentIndex],
            is_disabled: true,
            is_request: false,
          };

          this.model.is_galvanized =
            this.form[this.currentIndex].options[1].label;
            this.currentIndex++
        }
        return;
      }

      this.form[this.currentIndex] = {
        ...this.form[this.currentIndex],
        options: product.options || [],
      };
    },
    // TODO: Validate field on change?
    async handleSubmit() {
      // const isValid = Validator.validateForm(this.model, this.rules);
      // if (!isValid) return Utilities.showMessage('error', 'empty_fields_error');

      const res = await Database.create('reports', {
        answers: {
          product_id: this.product.id,
          ...this.model,
          material_id: this.material.id ?? null,
          material_type_id: this.material.material_type_id ?? null,
        },
        product_family: this.family,
      });

      if (res.status !== 201)
        return Utilities.showMessage('error', 'create_report_error');

      localStorage.setItem('answers', JSON.stringify(res.data));

      this.$router.push({ name: 'Configurator_Step3' });
    },
    async checkZipcode() {
      if (this.is_zip_checked) return;

      const zip = this.model.client_location;
      const length = zip.length;

      if (length > 5) {
        this.model.client_location = zip.substring(0, 4);
      }

      if (length < 5) return;

      if (length === 5) {
        const cities = await Database.getAll('distances', {
          type: 'check',
          zip_code: zip,
        });

        if (cities.length) this.is_zip_checked = true;
      }
    },
    // resetNextFields(index, item) {
    //   const fieldsToExclude = [
    //     'calculation_name',
    //     'project_name',
    //     'client_location',
    //   ];
    // },
    validateField(fieldName) {
      const value = this.model[fieldName];
      const rules = this.rules[fieldName];

      let errors = [];

      for (let i in rules) {
        const error = Validator.validateField(value, rules[i]);
        if (error) errors.push(error);
      }

      this.errors[fieldName] = errors.slice(-1);
    },
    getLabel(item) {
      const isRequired = item.required && item.type !== 'checkbox' ? '*' : '';
      const label = item.type === 'checkbox' ? ' ' : item.label;
      return `${label} ${isRequired}`;
    },
  },
};
</script>

<style lang="scss">
.tool-row {
  display: flex;
  align-items: center;
  .tool-img {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    img {
      width: 100%;
    }
  }

  .el-select {
    width: 100% !important;
  }
}
</style>
